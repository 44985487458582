
// ====================== SMALL COMPONENTS =====================
// .dcard{
//   width: 15rem;
//   @media (min-width: 768px) {
//     width: 17rem;
//   }
// }

// ====================== ADMIN COMPONENTS =====================

#wrapper {
    display: flex;
    #content-wrapper {
      overflow-x: hidden;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: $sticky-footer-height;
    }
  }

@mixin sidebar-icons {
  .nav-item {
    &:last-child {
      margin-bottom: 1rem;
    }
    .nav-link {
      text-align: center;
      padding: 0.75rem 1rem;
      width: $sidebar-collapsed-width;
      span {
        font-size: 0.65rem;
        display: block;
      }
    }
    .dropdown-menu {
      position: absolute !important;
      transform: none !important;
      left: calc(#{$sidebar-collapsed-width} + 0.5rem) !important;
      margin: 0;
      &.dropup {
        bottom: 0;
        top: auto !important;
      }
    }
    &.dropdown .dropdown-toggle::after {
      display: none;
    }
  }
}


// 1. Footer
footer.sticky-footer {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - #{$sidebar-collapsed-width});
    width: 100%;
    height: $sticky-footer-height;
    background-color: $gray-200;
    .copyright {
      line-height: 1;
      font-size: 0.8rem;
    }
    @media (min-width: 768px) {
      width: calc(100% - #{$sidebar-base-width});
    }
  }
  
  body.sidebar-toggled {
    footer.sticky-footer {
      width: 100%;
    }
    @media (min-width: 768px) {
      footer.sticky-footer {
        width: calc(100% - #{$sidebar-collapsed-width});
      }
    }
  }

// 2. Header & Sidenav
.navbar-nav {

    .form-inline .input-group {
      width: 100%;
    }
  
    .nav-item {
      &.active {
        .nav-link {
          color: $white;
        }
      }
      &.dropdown {
        .dropdown-toggle {
          &::after {
            width: 1rem;
            text-align: center;
            float: right;
            vertical-align: 0;
            border: 0;
            font-weight: 900;
            content: '\f105';
            font-family: 'Font Awesome 5 Free';
          }
        }
        &.show {
          .dropdown-toggle::after {
            content: '\f107';
          }
        }
        &.no-arrow {
          .dropdown-toggle::after {
            display: none;
          }
        }
      }
      .nav-link {
        &:focus {
          // remove outline for Safari and Firefox
          outline: none;
        }
        .badge {
          position: absolute;
          margin-left: 0.75rem;
          top: 0.3rem;
          font-weight: 400;
          font-size: 0.5rem;
        }
      }
    }
  
    @media(min-width: 768px) {
  
      .form-inline .input-group {
        width: auto;
      }
  
    }
  
  }
  
  .sidebar {
    span{
      cursor: pointer;
    }
    width: $sidebar-collapsed-width !important;
    background-color: $danger;
    min-height: calc(100vh - #{$navbar-base-height});
    @include sidebar-icons;
    .nav-item {
      .nav-link {
        color: fade-out($white, 0.25);
        &:active,
        &:focus,
        &:hover {
          color: fade-out($white, 0);
        }
      }
    }
    &.toggled {
      width: 0 !important;
      overflow: hidden;
    }
  }
  
  @media (min-width: 768px) {
    .sidebar {
      width: $sidebar-base-width !important;
  
      .nav-item {
        .nav-link {
          display: block;
          width: 100%;
          text-align: left;
          padding: 1rem;
          width: $sidebar-base-width;
          span {
            font-size: 1rem;
            display: inline;
          }
        }
        .dropdown-menu {
          position: static !important;
          margin: 0 1rem;
          // Position fix for Firefox
          top: 0;
        }
        &.dropdown .dropdown-toggle::after {
          display: block;
        }
      }
      &.toggled {
        overflow: visible;
        width: $sidebar-collapsed-width !important;
        @include sidebar-icons;
      }
    }
  }

  .menu{
    background-color:  #f34a53;
    border: 2px solid white;
    width: 10%;
    height: 10%;
    position: sticky;
    top: 0;
    left: 0%;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
  }

  .parent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 2% 1%;
    @media screen and (max-width:1300px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media screen and (max-width:700px) {
      grid-template-columns: 1fr;
    }
  }
  