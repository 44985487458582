.bar-chat-cp-city {
  padding-top: 20px;
  font-size: 12px;
  display: inline;
  margin-right: 10px;
  &-1 {
    display: inline;
    font-weight: 600;
  }
  &-2 {
    display: inline;
  }
}

.bar-chat-cp {
  margin-top: 13px;
}

.bar-chat-cp h6 {
  font-size: 16px;
  font-weight: 600;
}


