input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $muted-text;
  -webkit-text-fill-color: $secondary;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  @media screen and (max-width:800px) {
    -webkit-text-fill-color: $text;
  }
}

%forms {
  background: transparent;
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  padding: 1em 3em;
  border-radius: $border-radius;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0);
  transform: translateY(20%);

  @media screen and (max-width:800px) {
    padding: 1em 0;
    background: transparent;
    box-shadow: none;
  }
  .small{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 2%;
    margin-bottom: 1%;
    @media screen and (max-width:800px) {
      grid-template-columns: 49% 49%;
      .input-field{
        width: 100%;
      }
    }
  }

  h1{
      text-align: center;
      font-family: 'Great Vibes', cursive;
      margin: .5em 0;
      color: $text;
      font-size: 50px;
      @media screen and (max-width:1400px) {
        margin: 5% 0;
      }
      span{
        margin: 0 .5em;
      }
    }
  .input-field{
      padding: 1em 1.5em;
      background: $input-bg;
      margin: 3% 0;
      border-radius: $border-radius;
      box-shadow: inset 0px 0px 5px black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width:1400px) {
        padding: .8em 1.3em;
        margin: 1% 0;
      }

      @media screen and (max-width:800px) {
        padding: .5em 1em;
        box-shadow: inset 0px 0px 2px black;
        background: #cccccc1c;
        margin: 1.5% 0;
        border: 1px solid $muted-text;
      }

      input{
          border: none;
          color: black;
          outline: none;
          background-color: transparent;
          flex: 1;
          @media screen and (max-width:800px) {
            color: $text;
          }
      }
      i{
          color: #666;
          cursor: pointer;
          animation: scaler 1s ease-out;
          &:hover{
              color: #444;
          }
      }
  }
  p{
   color: $muted-text;   
  }

  a{
      transition: $trans;
      color: $text;
  }

  .error{
      color: $primary;
      margin: .5em auto;
  }

  button{
      @include btn();
      margin: 1em 0 1.5em 0;
      i{
          margin:0 1em;
          font-size: 15px;
          animation: spin 2s linear infinite;
      }
      @media screen and (max-width:1400px) {
        margin: .8em 0 1.2em 0;
      }

      @media screen and (max-width:800px) {
        width: 50%;
        margin: 1em auto;
        padding: 1em 0;
      }  
      
      @media screen and (max-width:500px) {
          width: 40%;
      }
  }
}

@keyframes bg{
  0%{
      background-position: right;
  }
  50%{
      background-position: left;
  }
  100%{
      background-position: right;
  }
}


.Auth{
  height: 100vh;
  overflow: hidden;
  @media screen and (max-width:800px) {
    min-height: 110vh;
  }
  .logo{
      @include logo();
      margin: 2% 5%;
    }
  .parent{
    display: flex;
    justify-content: space-between;
    max-width: 1600px;
    margin: auto;
    align-items: center;

    @media screen and (min-width:3001px) {
        width: 60%;
    }

    @media screen and (max-width:3000px) and (min-width:2000px) {
        width: 80%
    }

    @media screen and (max-width:800px) {
        width: 100%;
        margin: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        position: relative;
    }

    form{
      @extend %forms;
      flex: .25;

      @media screen and (min-width:800px) and (max-width:1600px) {
        flex:0.3;
        transform: translate(20%,20%);
        border: $border-radius;
        overflow: hidden;
      }

      .forgot{
          color: $muted-text;
          transition: $trans;
          &:hover{
              color: $text;
              text-decoration: none;
          }
          @media screen and (max-width:800px) {
            font-size: 13px;
          }
      }
      .match-error{
        margin: -.5em 0 1em .5em;
        color: $primary/1.5;
        letter-spacing: 1px;
        transition: $trans;
      }
    }
    section{
      position: absolute;
      width: 55%;
      height: 100%;
      background: url(../../assets/images/signInBg.jpg)no-repeat;
      background-size: cover;
      right: 0;
      top: 0;
      animation: bg 20s ease-in-out infinite;
      &::before{
        content:" ";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, $secondary,rgba(0, 0, 0, 0));
        left: 0;
        top: 0;
      }
      &::after{
        content:" ";
        position: absolute;
        width: 100%;
        height: 80%;
        background: linear-gradient(2deg, rgba(0, 0, 0, 01) , rgba(0, 0, 0, 0) 100%);
        left: 0;
        bottom: 0;
      }
      @media screen and (max-width:800px) {
        display: none;
      }
    }
  }
  .signup{
    form{
      transform: translateX(20%);
      border: $border-radius;
      overflow: hidden;
      padding: 0 ;
      box-shadow: none;
      background: none;
      @media screen and (max-width:800px) {
        transform: translateX(0%);
        width: 90%;
      }

      h1{
        text-align: center;
        font-family: 'Great Vibes', cursive;
        margin: 0;
        color: $text;
        font-size: 50px;
        @media screen and (max-width:800px) {
          margin: 5% 0 1.5% 0;
        }

        span{
          margin: 0 .5em;
        }
      }

      .input-field{
          padding: .8em 1em;
          background: $input-bg;
          margin: 1.3% 0;
          border-radius: $border-radius;
          box-shadow: inset 0px 0px 5px black;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width:1400px) {
            padding: .2em .5em;
            margin: 1% 0;
          }
    
          @media screen and (max-width:800px) {
            padding: .5em 1em;
            box-shadow: inset 0px 0px 2px black;
            background: #cccccc1c;
            margin: 1.5% 0;
            border: 1px solid $muted-text;
          }
    
          input{
              border: none;
              color: black;
              outline: none;
              background-color: transparent;
              flex: 1;
              @media screen and (max-width:800px) {
                color: $text;
              }
          }
          i{
              color: #666;
              cursor: pointer;
              animation: scaler 1s ease-out;
              &:hover{
                  color: #444;
              }
          }
      }

      .coller{
        border: $border-radius;
        overflow: hidden;
        margin: 1.5% 0;
        .PhoneInput{
            width: 100%;
            background: $muted-text/2;
            margin-bottom: -2%;
            .PhoneInputCountry{
              padding: 1em;
              background: $secondary;
            }
            input{
              background: transparent;
              border: none;
              outline:none;
              color: $text;
            }
        }
      }

      .terms{
        margin-top: 2%;
        input{
          margin: 0 3%;
        }
      }
      
      button{
        margin: 1.5% 0;
        @media screen and (max-width:800px) {
          margin: 2% auto;
        }
      }
    }
  }
}